import PropTypes from 'prop-types'
import {checkUrl} from '../../../utils'


const Image = ({url, alt, ...props}) => {
  return (
    <img srcSet={checkUrl(url)} alt={alt} {...props} />
  )
}

Image.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
}

export default Image
