
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Link} from 'gatsby'
import {isExternal, checkUrl} from '../../../utils'


const StyledLink = styled.a`
  cursor: pointer;
  font-family: ${({theme}) => theme.components.typography.fonts.heading};
  font-size: ${({theme}) => theme.components.typography.variants.a};
`

const chooseProps = (external, to) => {
  const link = checkUrl(to)

  if (external) {
    return {
      href: link,
      rel: 'noopener noreferrer',
      target: '_blank',
    }
  }

  return {
    to: link,
    as: Link,
  }
}

const BaseLink = ({to, children, isExternal: isDefaultExternal, ...props}) => {
  const external = isExternal(to) || isDefaultExternal
  return (
    <StyledLink {...chooseProps(external, to)} {...props}>
      {children}
    </StyledLink>
  )
}

BaseLink.propTypes = {
  to: PropTypes.string,
  isExternal: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default BaseLink
