import React from 'react'
import styled, {css} from 'styled-components'
import {graphql, useStaticQuery} from 'gatsby'
import {isEmpty} from 'lodash-es'
import {media} from '../../../utils'
import {getCookie, setCookie} from '../../../cookies'
import Button from '../Button/Button'


const StyledBar = styled.div`
  display: flex;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
  opacity: 0;
  z-index: 9999;
  background-color: ${({theme}) => theme.colors.secondary};
  padding: 0.5rem 1.5rem;

  ${({isVisible}) => isVisible && css`
    transform: translateY(0);
    opacity: 0.9;
  `}

  ${media.down('mobile')(css`
    flex-flow: column;
    margin: 0 auto;
    padding: 1.25rem 0.625rem;
    text-align: center;
    width: 98%;
    left: 0;
    right: 0;
  `)}
`

const StyledText = styled.p`
  display: block;
  color: ${({theme}) => theme.colors.textInverted};

  ${media.up('mobile')(css`
    margin-right: 0.5rem;
  `)}
`

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({theme}) => theme.colors.primary};

  ${media.up('mobile')(css`
    margin-right: 1rem;
  `)}
`

const StyledButton = styled(Button)`
  border: 1px solid  ${({theme}) => theme.colors.primary};
  padding: 0.5rem 1.25rem;

  &:hover {
    background-color: ${({theme}) => theme.colors.primary};
  }

  ${media.down('mobile')(css`
    margin-top: 1rem;
  `)}
`

const CookieBar = ({...props}) => {
  const [localConsent, setLocalConsent] = React.useState(true)

  React.useEffect(() => {
    setLocalConsent(getCookie('hasConsented'))
  }, [])

  const giveConsent = () => {
    setLocalConsent(true)
    setCookie('hasConsented', true, 30, '/')
  }

  const data = useStaticQuery(graphql`
    query CookieBar {
      wp {
        options {
          options {
            footer {
              cookieBar {
                cookieBarText
                buttonConsentText
                link {
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const {cookieBar} = data?.wp?.options?.options?.footer

  return (
    <If condition={!isEmpty(cookieBar)}>
      <StyledBar isVisible={!localConsent} {...props}>
        <StyledText>{cookieBar?.cookieBarText}</StyledText>
        <If condition={!isEmpty(cookieBar?.link?.url) && !isEmpty(cookieBar?.link?.title)}>
          <StyledLink href={cookieBar?.link?.url}>
            {cookieBar?.link?.title}
          </StyledLink>
        </If>
        <If condition={!isEmpty(cookieBar?.buttonConsentText)}>
          <StyledButton onClick={giveConsent}>
            {cookieBar?.buttonConsentText}
          </StyledButton>
        </If>
      </StyledBar>
    </If>
  )
}

export default CookieBar
