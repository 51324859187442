
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {ArrowRightIcon} from '../../../assets/icons'
import {media} from '../../../utils'
import {BaseButton} from '../../base'


const StyledArrow = styled(ArrowRightIcon)`
  transform-origin: 0;
  transition: ${({theme}) => `transform ${theme.global.duration} ease-in`};
  width: 2rem;
`

const StyledSpan = styled.span`
  display: inline-block;
  transition: ${({theme}) => `transform ${theme.global.duration} ease-in`};
  line-height: 1.2;
`

const buttonTypes = {
  contain: css`
    transition: ${({theme}) => (`
      background ${theme.global.duration} ease-out, color ${theme.global.duration} ease-out
    `)};
    border: 1px solid ${({theme, $inverted}) => $inverted
    ? theme.colors.textInverted
    : theme.colors.text};
    background: ${({theme, $inverted}) => $inverted
    ? 'transparent'
    : theme.colors.textInverted};
    padding: 1.25rem 2.5rem;
    text-align: center;
    color: ${({theme, $inverted}) => $inverted ? theme.colors.textInverted : theme.colors.text};

    &:hover {
      background: ${({theme, $inverted}) => $inverted ? theme.colors.textInverted : theme.colors.text};
      color: ${({theme, $inverted}) => $inverted ? theme.colors.text : theme.colors.textInverted};
    }

    ${media.down('mobile')(css`
      padding: 1rem 1.5rem;
    `)}
  `,

  text: css`
    transition: ${({theme}) => `transform ${theme.global.duration} ease-in`};
    color: ${({theme, $inverted}) => $inverted ? theme.colors.textInverted : theme.colors.text};

    ${StyledArrow} {
      path,
      rect {
        fill: ${({theme, $inverted}) => $inverted ? theme.colors.textInverted : theme.colors.text};
      }
    }

    ${StyledSpan} {
      padding-left: 0.5rem;
    }

    &:hover,
    &:focus {
      ${StyledSpan} {
        transform: translateX(1rem);
      }

      ${StyledArrow} {
        transform: scaleX(1.5);

        path,
        rect {
          fill: ${({theme}) => theme.colors.primary};
        }
      }
    }
  `,
}

const StyledButton = styled(BaseButton)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  ${({type}) => buttonTypes[type]}
`

const Button = ({type, inverted, children, ...props}) => (
  <StyledButton type={type} $inverted={inverted} {...props}>
    <If condition={type === 'text'}>
      <StyledArrow />
    </If>
    <StyledSpan>
      {children}
    </StyledSpan>
  </StyledButton>
)

Button.defaultProps = {
  type: 'contain',
  inverted: false,
}

Button.propTypes = {
  type: PropTypes.oneOf(['contain', 'text']),
  inverted: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Button
