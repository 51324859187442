
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {BaseLink} from '../../base'


const StyledLink = styled(BaseLink)`
  line-height: 2;
  color: ${({theme}) => theme.colors.textInverted};
`

const FooterLink = ({to, children, ...props}) => (
  <StyledLink to={to} {...props}>
    {children}
  </StyledLink>
)

FooterLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default FooterLink
