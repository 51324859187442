
import PropTypes from 'prop-types'
import {keys, isObject} from 'lodash-es'
import styled, {css} from 'styled-components'
import theme from '../../../theme/theme'
import {media} from '../../../utils'


const {components: {typography: {weight, variants, fonts: {heading, text}}}} = theme

const chooseFontSize = (variant) => {
  if (isObject(variant)) {
    return css`
      font-size: ${variant.desktop};

      ${media.down('mobile')(css`
        font-size: ${variant.mobile};
      `)}
    `
  }

  return css`
    font-size: ${variant};
  `
}

const baseTextStyles = css`
  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ul {
    list-style-type: circle;
    list-style-position: inside;
  }

  ol {
    list-style-type: upper-arabic;
    list-style-position: inside;
  }

  del {
    text-decoration: line-through;
  }

  h1 {
    line-height: 1;
    font-family: ${heading};
    ${chooseFontSize(variants.h1)}
  }

  h2 {
    line-height: 1;
    font-family: ${heading};
    ${chooseFontSize(variants.h2)}
  }

  h3 {
    line-height: 1;
    font-family: ${heading};
    ${chooseFontSize(variants.h3)}
  }

  h4 {
    line-height: 1;
    font-family: ${text};
    ${chooseFontSize(variants.h4)}
  }

  a {
    color: ${({theme}) => theme.colors.primary};
  }
`

const types = {
  p: css`
    line-height: 2;
    font-family: ${text};
  `,
  h1: css`
    line-height: 1;
    font-family: ${heading};
  `,
  h2: css`
    line-height: 1;
    font-family: ${heading};
  `,
  h3: css`
    line-height: 1;
    font-family: ${heading};
  `,
  h4: css`
    line-height: 1;
    font-family: ${text};
  `,
  div: css`
    ${baseTextStyles}
    line-height: 2;
    font-family: ${text};
  `,
  span: css`
    line-height: 2;
  `,
}

const StyledText = styled.p`
  ${({type}) => types[type]}
  ${({type}) => chooseFontSize(variants[type])}

  ${({thin}) => thin && css`
    font-weight: ${weight.light};
  `}

  ${({uppercase}) => uppercase && css`
    text-transform: uppercase;
  `}

  ${({srOnly}) => srOnly && css`
    position: absolute;
    top: -9999px;
    left: -9999px;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
  `}
`

const Typography = ({type, isHeading, uppercase, srOnly, thin, children, ...props}) => (
  <StyledText
      as={type}
      type={type}
      isHeading={isHeading}
      uppercase={uppercase}
      srOnly={srOnly}
      thin={thin}
      dangerouslySetInnerHTML={{__html: children}}
      {...props}
  />
)

Typography.propTypes = {
  type: PropTypes.oneOf([...keys(variants)], 'base'),
  isHeading: PropTypes.bool,
  uppercase: PropTypes.bool,
  srOnly: PropTypes.bool,
  thin: PropTypes.bool,
  children: PropTypes.node,
}

Typography.defaultProps = {
  isHeading: false,
  uppercase: false,
  srOnly: false,
  thin: false,
  type: 'p',
}


export default Typography
