
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BaseLink from '../BaseLink/BaseLink'


const StyledButton = styled.button`
  cursor: pointer;
  font-family: ${({theme}) => theme.components.typography.fonts.heading};
  font-size: ${({theme}) => theme.components.typography.variants.a};
`

const BaseButton = ({to, children, onClick, ...props}) => {
  const as = to ? BaseLink : 'button'

  return (
    <StyledButton as={as} to={to} onClick={onClick} {...props}>
      {children}
    </StyledButton>
  )
}

BaseButton.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default BaseButton
