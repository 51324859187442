import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'
import {map} from 'lodash-es'
import {checkUrl} from '../../../utils'


const Seo = ({lang, meta, title, langToSlug, seo}) => {
  const {site, wp} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        wp {
          languages {
            code
          }
        }
      }
    `
  )

  const metaDescription = seo?.metaDesc || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const languages = wp?.languages

  const alternateLinks = map(languages, ({code}) => ({
    rel: 'alternate',
    hrefLang: code,
    href: langToSlug[code],
  }))

  return (
    <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        defaultTitle={defaultTitle}
        link={[...alternateLinks]}
        meta={[
          {
            name: 'description',
            content: metaDescription,
          },
          {
            property: 'og:title',
            content: seo?.opengraphTitle || title || defaultTitle,
          },
          {
            property: 'og:description',
            content: seo?.opengraphDescription || metaDescription,
          },
          {
            property: 'og:type',
            content: seo?.opengraphType || 'website',
          },
          {
            property: 'og:image',
            content: checkUrl(seo?.opengraphImage?.mediaItemUrl),
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:creator',
            content: site.siteMetadata?.author || '',
          },
          {
            name: 'twitter:title',
            content: seo?.twitterTitle?.title || title || defaultTitle,
          },
          {
            name: 'twitter:description',
            content: seo?.twitterDescription || metaDescription,
          },
          {
            name: 'twitter:image',
            content: seo?.twitterImage?.mediaItemUrl,
          },
        ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: 'sk',
  meta: [],
}

Seo.propTypes = {
  seo: PropTypes.object,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  langToSlug: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

export default Seo
