import {useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {graphql, useStaticQuery} from 'gatsby'
import {isEmpty, filter, toLower, find} from 'lodash-es'
import {MenuIcon, CloseIcon} from '../../../assets/icons'
import {media} from '../../../utils'
import {BaseLink, ContentContainer} from '../../base'
import Image from '../../base/Image/Image'
import Button from '../Button/Button'


const StyledHeader = styled.header`
  position: absolute;
  z-index: 2;
  width: 100%;
`

const StyledImage = styled(Image)`
  position: relative;
  z-index: 2;
  width: 6.5rem;
  height: 100%;
`

const StyledHeaderContainer = styled(ContentContainer.Gutter)`
  display: flex;
  justify-content: space-between;
  padding: 3.5rem 1rem;

  ${media.down('desktop')(css`
    padding: 3.5rem 1rem;
  `)}

  ${media.down('mobile')(css`
    padding: 2rem 1rem;
  `)}
`

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
`

const StyledNavContainer = styled.div`
  display: flex;
  align-self: center;
  width: 100%;

  li:first-child {
    margin-top: 7rem;
  }

  ${media.down('tablet')(css`
    background-color: #1A1A1A;
    position: absolute;
    flex-direction: column;
    align-self: self-start;
    padding-top: 8.5rem;
    margin-right: 0;
    top: 0;
    right: 0;
  `)}

  ${media.down('mobile')(css`
    padding-top: 7rem;
    top: 0;
    right: 0;
  `)}
`

const StyledLink = styled(BaseLink)`
  display: ${({active}) => active ? 'none' : 'block'};
  margin-right: 2.25rem;
  color: white;

  ${media.down('desktop')(css`
    margin-right: 1.5rem;
  `)}

  ${media.down('tablet')(css`
    display: block;
    margin-bottom: 2rem;
    margin-left: 2rem;
    text-align: left;
  `)}

  ${media.down('mobile')(css`
    margin-left: 1.5rem;
  `)}
`
const StyledMenuButton = styled(Button)`
  display: block;
  margin-right: 2.25rem;
  padding: 0.25rem 0.75rem;

  span {
    padding-left: 0;
    white-space: nowrap;
  }

  ${media.down('desktop')(css`
    margin-right: 1.5rem;
  `)}

  ${media.down('tablet')(css`
    display: ${({active}) => active ? 'none' : 'block'};
    z-index: 1;
  `)}
`

const StyledSwitcherWrapper = styled.div`
  margin-right: 2.25rem;
  max-height: 2rem;
`

const StyledSwitcherContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  transition: max-height 200ms ease-in-out;
  z-index: 1000;
  border: 1px solid ${({theme}) => theme.colors.textInverted};
  padding: 0.35rem 0.4rem;
  max-height: 2rem;

  &:hover,
  &:focus {
    max-height: 12rem;
  }
`

const StyledFlag = styled.div`
  position: relative;
  transition: opacity ${({theme}) => theme.global.duration} ease-in-out;
  opacity: ${({isActive}) => isActive ? '1' : '0'};
  z-index: 0;
  text-align: center;

  :not(:last-child) {
    padding-bottom: 0.4rem;
  }

  img {
    width: 1.75rem;
  }

  ${StyledSwitcherContainer}:hover &,
  ${StyledSwitcherContainer}:focus & {
    opacity: 1;
  }
`

const StyledBaseLink = styled(BaseLink)`
  :not(:last-child) {
    padding-bottom: 0.4rem;
  }
`

const StyledButton = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
`

const StyledCloseIcon = styled(CloseIcon)`
  position: relative;
  z-index: 2;
  width: 1.75rem;
  color: white;
`

const StyledOpenIcon = styled(MenuIcon)`
  width: 1.75rem;
  color: white;
`

const Header = ({lang, langToSlug}) => {
  const data = useStaticQuery(graphql`
    query Menu {
      headerMenu: allWpMenu(filter: {name: {glob: "MainMenu*"}}) {
        nodes {
          menuItems {
            nodes {
              url
              id
              title: label
              menuItemLinkMenu {
                showOnlyOnDesktop
              }
            }
          }
          name
          language
        }
      }
      linkMenu: allWpMenu(filter: {name: {glob: "LinkMenu*"}}) {
        nodes {
          menuItems {
            nodes {
              url
              id
              title: label
              menuItemLinkMenu {
                showOnlyOnDesktop
              }
            }
          }
          name
          language
        }
      }
      wp {
        options {
          options {
            navigation {
              navigationLogo {
                logoImage {
                  altText
                  sourceUrl
                }
              }
            }
            languages {
              flag {
                sourceUrl
              }
              name
              link {
                url
              }
            }
          }
        }
      }
    }
  `)

  const headerMenu = find(data?.headerMenu?.nodes, {language: lang})?.menuItems?.nodes
  const linkMenu = find(data?.linkMenu?.nodes, {language: lang})?.menuItems?.nodes
  const languages = data?.wp?.options?.options?.languages

  const [navbarOpen, setNavbarOpen] = useState(false)

  const otherLanguages = filter(languages, (language) => {
    return language.name?.toLowerCase() !== lang
  })

  const [currentLang] = filter(languages, (current) => {
    return current.name?.toLowerCase() === lang
  })

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }

  const closeMenu = () => {
    setNavbarOpen(false)
  }

  return (
    <StyledHeader>
      <StyledHeaderContainer>
        <If condition={!isEmpty(data.wp?.options.options.navigation?.navigationLogo.logoImage?.sourceUrl)}>
          <BaseLink to={lang !== 'sk' ? `/${lang}` : '/'}>
            <StyledImage
                url={data.wp.options.options.navigation?.navigationLogo.logoImage?.sourceUrl}
                alt={data.wp.options.options.navigation?.navigationLogo.logoImage?.altText}
                loading="lazy"
            />
          </BaseLink>
        </If>
        <StyledNav>
          <If condition={navbarOpen && headerMenu}>
            <StyledNavContainer navbarOpen={navbarOpen}>
              <For each="menuItem" of={headerMenu}>
                <StyledLink
                    key={menuItem?.id}
                    to={menuItem?.url}
                    onClick={() => closeMenu()}
                    active={menuItem.menuItemLinkMenu.showOnlyOnDesktop}
                >
                  {menuItem?.title}
                </StyledLink>
              </For>
            </StyledNavContainer>
          </If>
          <If condition={!isEmpty(linkMenu)}>
            <For each="menuItem" of={linkMenu}>
              <StyledMenuButton
                  isExternal
                  key={menuItem.id}
                  to={menuItem.url}
                  inverted
                  active={menuItem.menuItemLinkMenu.showOnlyOnDesktop}
              >
                {menuItem.title}
              </StyledMenuButton>
            </For>
          </If>
          <If condition={!isEmpty(currentLang?.flag?.sourceUrl)}>
            <StyledSwitcherWrapper>
              <StyledSwitcherContainer>
                <StyledFlag isActive>
                  <img src={currentLang.flag?.sourceUrl} alt={currentLang.name} />
                </StyledFlag>
                <For each="language" of={otherLanguages}>
                  <If condition={!isEmpty(language.name) && !isEmpty(language.flag.sourceUrl)}>
                    <StyledBaseLink key={language.name} to={langToSlug[toLower(language.name)]}>
                      <StyledFlag>
                        <img src={language.flag.sourceUrl} alt={language.name} />
                      </StyledFlag>
                    </StyledBaseLink>
                  </If>
                </For>
              </StyledSwitcherContainer>
            </StyledSwitcherWrapper>
          </If>
          <StyledButton title="menu tlačidlo" onClick={handleToggle} type="button">
            <Choose>
              <When condition={navbarOpen}>
                <StyledCloseIcon />
              </When>
              <Otherwise>
                <StyledOpenIcon />
              </Otherwise>
            </Choose>
          </StyledButton>
        </StyledNav>
      </StyledHeaderContainer>
    </StyledHeader>
  )
}

Header.propTypes = {
  lang: PropTypes.string,
  langToSlug: PropTypes.object,
}

export default Header
