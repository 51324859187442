import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {graphql, useStaticQuery} from 'gatsby'
import {find, isEmpty} from 'lodash-es'
import {CopyrightLogo} from '../../../assets/icons'
import {media} from '../../../utils'
import {BaseLink, ContentContainer, Typography} from '../../base'
import Image from '../../base/Image/Image'
import FooterLink from './FooterLink'


const StyledFooter = styled.footer`
  display: block;
  margin: 0 auto;
  background: transparent;
  width: 100%;
`

const StyledFooterMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem 0;

  ${media.down('mobile')(css`
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  `)}
`

const StyledLogo = styled(BaseLink)`
  height: 100%;

  img {
    width: 9.5rem;
    height: 100%;
  }

  ${media.down('mobile')(css`
    margin-bottom: 1.5rem;
    margin-left: 0;
  `)}
`

const StyledNavColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.down('mobile')(css`
    align-items: center;

    :nth-child(3) {
      margin-bottom: 1rem;
    }
  `)}
`

const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 12.5rem;

  ${media.down('mobile')(css`
    align-items: center;
    margin-right: 0;
  `)}
`

const StyledSocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  width: 5rem;
`

const StyledContactIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 5.5rem;

  ${media.down('mobile')(css`
    align-items: center;
    height: 4.75rem;
  `)}
`

const StyledLink = styled(BaseLink)`
  display: flex;
  align-content: center;
  color: ${({theme}) => theme.colors.textInverted};

  svg {
    margin-right: 0.75rem;
    height: 1.25rem;
  }
`

const StyledFooterCopyright = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  color: ${({theme}) => theme.colors.textInverted};

  ${media.down('desktop')(css`
    margin: 0 3.5rem;
  `)}

  ${media.down('mobile')(css`
    margin: 0 1.75rem;
  `)}

  ${media.down(576)(`
    flex-direction: column;
    padding: 1rem 0;
    height: 5.5rem;
  `)}
`

const StyledCopyrightLogo = styled(BaseLink)`
  svg {
    height: 0.8rem;
  }
`


const Footer = ({lang, langToSlug, ...props}) => {
  const data = useStaticQuery(graphql`
    query FooterMenu {
    footerMenu: allWpMenu(filter: {name: {glob: "FooterMenu*"}}) {
        nodes {
          menuItems {
            nodes {
              url
              id
              title: label
            }
          }
          name
          language
        }
      }
      wp {
        options {
          options {
            navigation {
              navigationLogo {
                logoImage {
                  sourceUrl
                  altText
                }
              }
            }
            footer {
              links {
                name
                icon
                link
              }
              contacts {
                text
                icon
                type
              }
            }
          }
        }
      }
    }
  `)

  const footerMenu = find(data?.footerMenu?.nodes, {language: lang})?.menuItems?.nodes
  const {logoImage: logo} = data?.wp?.options?.options?.navigation?.navigationLogo
  const {footer: {links, contacts}} = data?.wp?.options?.options

  const half = Math.ceil(footerMenu?.length / 2)
  const column1 = footerMenu?.slice(0, half)
  const column2 = footerMenu?.slice(half, footerMenu.length)

  const contactTypes = {
    email: 'mailTo:',
    phone: 'tel:',
  }

  return (
    <ContentContainer.Gutter>
      <StyledFooter {...props}>
        <StyledFooterMenu>
          <If condition={!isEmpty(logo?.sourceUrl)}>
            <StyledLogo to={langToSlug[lang]}>
              <Image url={logo.sourceUrl} alt={logo.altText} loading="lazy" />
            </StyledLogo>
          </If>
          <If condition={!isEmpty(column1)}>
            <StyledNavColumn>
              <For each="item" of={column1}>
                <FooterLink key={item.id} to={item.url}>{item.title}</FooterLink>
              </For>
            </StyledNavColumn>
          </If>
          <If condition={!isEmpty(column2)}>
            <StyledNavColumn>
              <For each="item" of={column2}>
                <FooterLink key={item.id} to={item.url}>{item.title}</FooterLink>
              </For>
            </StyledNavColumn>
          </If>
          <StyledInfoWrapper>
            <If condition={!isEmpty(links)}>
              <StyledSocialIcons>
                <For each="link" of={links} index="idx">
                  <StyledLink key={idx} to={link.link}>
                    <Typography srOnly>{link.name}</Typography>
                    <span dangerouslySetInnerHTML={{__html: link.icon}} />
                  </StyledLink>
                </For>
              </StyledSocialIcons>
            </If>
            <If condition={!isEmpty(contacts)}>
              <StyledContactIcons>
                <For each="contact" of={contacts} index="idx">
                  <StyledLink key={idx} to={`${contactTypes[contact.type]}${contact.text}`} isExternal>
                    <Typography srOnly>{contact.text}</Typography>
                    <span dangerouslySetInnerHTML={{__html: contact.icon}} />
                    {contact.text}
                  </StyledLink>
                </For>
              </StyledContactIcons>
            </If>
          </StyledInfoWrapper>
        </StyledFooterMenu>
        <StyledFooterCopyright>
          <Typography thin>Copyright MRAVA, s.r.o.</Typography>
          <StyledCopyrightLogo to="https://www.sited.sk">
            <Typography srOnly>Copyright logo</Typography>
            <CopyrightLogo />
          </StyledCopyrightLogo>
        </StyledFooterCopyright>
      </StyledFooter>
    </ContentContainer.Gutter>
  )
}

Footer.propTypes = {
  lang: PropTypes.string,
  langToSlug: PropTypes.object,
}

export default Footer
