import PropTypes from 'prop-types'
import styled from 'styled-components'
import CookieBar from '../CookieBar/CookieBar'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Seo from '../Seo/Seo'


const StyledMain = styled.main`
  min-height: 30rem;
`

const AppShell = ({title, seo, langToSlug, lang, children}) => {
  return (
    <>
      <Seo title={title} seo={seo} langToSlug={langToSlug} />
      <Header lang={lang} langToSlug={langToSlug} />
      <StyledMain>{children}</StyledMain>
      <Footer lang={lang} langToSlug={langToSlug} />
      <CookieBar />
    </>
  )
}

AppShell.propTypes = {
  title: PropTypes.string,
  seo: PropTypes.object,
  langToSlug: PropTypes.object,
  lang: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default AppShell
