
import PropTypes from 'prop-types'
import {map} from 'lodash-es'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'


const StyledWrapper = styled.section`
  background-color: ${({transparent, theme}) => transparent ? 'transparent' : theme.colors.background};
  padding: 8.125rem 0;

  ${({compact}) => compact && css`
    padding: 2.5rem 0;
  `}
`

const StyledGutter = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: ${({theme}) => theme.global.containerMaxWidths.desktop};

  ${({theme}) => {
    const maxWidths = map(theme.global.containerMaxWidths, (value, key) => {
      return media.down(key)(css`
        max-width: ${value};
      `)
    })

    return maxWidths
  }}
`

const ContentContainer = ({transparent, children, ...props}) => (
  <StyledWrapper transparent={transparent} {...props}>
    <StyledGutter>
      {children}
    </StyledGutter>
  </StyledWrapper>
)

ContentContainer.propTypes = {
  transparent: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

ContentContainer.Gutter = StyledGutter

export default ContentContainer
